import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [
        "totalBalanceMinutes", "usedBalanceMinutes",
        "totalBalanceFields", "totalBalanceDaysField", "totalBalanceHoursField",
        "usedBalanceFields", "usedBalanceDaysField", "usedBalanceHoursField"
    ]

    connect() {
        this.workDayMinutes = parseInt(this.element.dataset.leaveTypeBalanceStandardWorkdayMinutes);
        this.initializeFields()

        const totalBalanceUnitElem = this.element.querySelector('[name*="total_balance_unit"]')
        const usedBalanceUnitElem = this.element.querySelector('[name*="used_balance_unit"]')

        this.toggleTotalBalanceFields({ target: totalBalanceUnitElem })
        this.toggleUsedBalanceFields({ target: usedBalanceUnitElem })
    }

    initializeFields() {
        this.updateTotalBalanceMinutes()
        this.updateUsedBalanceMinutes()
    }

    toggleTotalBalanceFields(event) {
        const unit = event.target.value
        if (unit === 'days') {
            this.totalBalanceDaysFieldTarget.style.display = 'block'
            this.totalBalanceHoursFieldTarget.style.display = 'none'
            this.enableFields(this.totalBalanceDaysFieldTarget)
            this.disableFields(this.totalBalanceHoursFieldTarget)
        } else {
            this.totalBalanceDaysFieldTarget.style.display = 'none'
            this.totalBalanceHoursFieldTarget.style.display = 'block'
            this.enableFields(this.totalBalanceHoursFieldTarget)
            this.disableFields(this.totalBalanceDaysFieldTarget)
        }
        this.updateTotalBalanceMinutes()
    }

    toggleUsedBalanceFields(event) {
        const unit = event.target.value
        if (unit === 'days') {
            this.usedBalanceDaysFieldTarget.style.display = 'block'
            this.usedBalanceHoursFieldTarget.style.display = 'none'
            this.enableFields(this.usedBalanceDaysFieldTarget)
            this.disableFields(this.usedBalanceHoursFieldTarget)
        } else {
            this.usedBalanceDaysFieldTarget.style.display = 'none'
            this.usedBalanceHoursFieldTarget.style.display = 'block'
            this.enableFields(this.usedBalanceHoursFieldTarget)
            this.disableFields(this.usedBalanceDaysFieldTarget)
        }
        this.updateUsedBalanceMinutes()
    }

    updateTotalBalanceMinutes() {
        const unit = this.element.querySelector('[name*="total_balance_unit"]').value;
        let totalMinutes = 0;

        if (unit === 'days') {
            // Gün + Saat + Dakika
            const days = parseInt(this.totalBalanceDaysFieldTarget.querySelector('input[name*="total_balance_days"]').value) || 0;
            const hours = parseInt(this.totalBalanceDaysFieldTarget.querySelector('input[name*="total_balance_hours"]').value) || 0;
            const minutes = parseInt(this.totalBalanceDaysFieldTarget.querySelector('input[name*="total_balance_remaining_minutes"]').value) || 0;

            totalMinutes = (days * this.workDayMinutes) + (hours * 60) + minutes;
        } else {
            // Saat + Dakika
            const hours = parseInt(this.totalBalanceHoursFieldTarget.querySelector('input[name*="total_balance_hours"]').value) || 0;
            const minutes = parseInt(this.totalBalanceHoursFieldTarget.querySelector('input[name*="total_balance_remaining_minutes"]').value) || 0;

            totalMinutes = (hours * 60) + minutes;
        }

        // Dakika toplamını gizli alanına yaz
        this.totalBalanceMinutesTarget.value = totalMinutes;
    }

    updateUsedBalanceMinutes() {
        const unit = this.element.querySelector('[name*="used_balance_unit"]').value;
        const workDayMinutes = 450;

        let totalMinutes = 0;

        if (unit === 'days') {
            const days = parseInt(this.usedBalanceDaysFieldTarget.querySelector('input[name*="used_balance_days"]').value) || 0;
            const hours = parseInt(this.usedBalanceDaysFieldTarget.querySelector('input[name*="used_balance_hours"]').value) || 0;
            const minutes = parseInt(this.usedBalanceDaysFieldTarget.querySelector('input[name*="used_balance_remaining_minutes"]').value) || 0;

            totalMinutes = (days * workDayMinutes) + (hours * 60) + minutes;
        } else {
            const hours = parseInt(this.usedBalanceHoursFieldTarget.querySelector('input[name*="used_balance_hours"]').value) || 0;
            const minutes = parseInt(this.usedBalanceHoursFieldTarget.querySelector('input[name*="used_balance_remaining_minutes"]').value) || 0;
            totalMinutes = (hours * 60) + minutes;
        }

        this.usedBalanceMinutesTarget.value = totalMinutes;
    }

    enableFields(target) {
        target.querySelectorAll('input').forEach(input => {
            input.removeAttribute('disabled')
        })
    }

    disableFields(target) {
        target.querySelectorAll('input').forEach(input => {
            input.setAttribute('disabled', 'disabled')
        })
    }
}
